
import { defineComponent, ref, onMounted, nextTick } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";
import { configProviderContextKey } from "element-plus";
import { propsToAttrMap } from "@vue/shared";

import PickClient from "@/views/crafted/authentication/PickClient.vue";
import { Image } from "@amcharts/amcharts4/core";
export default defineComponent({
  name: "sign-in",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  props: {
    client: Object,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    let clients;

    const avisoFerias = ref<HTMLImageElement | null>(null);

    // onMounted hook runs after the component is mounted
    onMounted(() => {
      if(validateDates('2023-08-25')) {
        avisoFerias.value?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'center',
          //top: -800,
        });  
      }
    });

    const validateDates = (endDate: string | null = null) => {
      if(endDate === null || endDate === "") return false;
      const today = new Date().toISOString().slice(0, 10);
      return today <= endDate!;
    }

    const openImage = () => {
      Swal.fire({
        showCancelButton: false,
        showConfirmButton: false,
        imageUrl: "media/banners/ferias_vertical.jpg",
        imageAlt: "Aviso de Férias",
        showCloseButton: true,
        padding: '1.5rem 0 0 0',
      });
    };

    const submitButton = ref<HTMLButtonElement | null>(null);

    //Create form validation object
    const login = Yup.object().shape({
      //email: Yup.string().email().required().label("Email"),
      password: Yup.string().min(1).required().label("Password"),
    });

    //Form submit function
    const onSubmitLogin = (values) => {
      // Clear existing errors
      store.dispatch(Actions.LOGOUT);

      if (submitButton.value) {
        // eslint-disable-next-line
        submitButton.value!.disabled = true;
        // Activate indicator
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }

      // Dummy delay
      setTimeout(() => {
        // Send login request
        store.dispatch(Actions.LOGIN, values).then((response) => {
          switch (response) {
            case 200:
              //INITIAL LOAD
              store.dispatch(Actions.LOAD_PRODUCTS);
              //store.dispatch(Actions.LOAD_DASH_INVOICES);
              //store.dispatch(Actions.LOAD_INDEBT);
              //store.dispatch(Actions.LOAD_DASH_INVOICES);
              //store.dispatch(Actions.LOAD_NOTIFICATIONS);

              //testing request from here

              // Go to page after successfully login
              router.push({ name: "pickClient" });

              break;
            case 401:
              Swal.fire({
                text: "Credenciais inválidas",
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Tente novamente!",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-danger",
                },
              });
              break;
            default:
              Swal.fire({
                text: "Erro inesperado, tente mais tarde",
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Ok",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-danger",
                },
              });
              break;
          }
        });
        //Deactivate indicator
        submitButton.value?.removeAttribute("data-kt-indicator");
        // eslint-disable-next-line
        submitButton.value!.disabled = false;
      }, 3000);
    };

    return {
      onSubmitLogin,
      validateDates,
      openImage,
      avisoFerias,
      login,
      submitButton,
    };
  },
});
